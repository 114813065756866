import * as React from "react";
import Redirect from "../../components/redirect";
//--------------------------------------------------

  const url = "https://yucolab-hongkong.slack.com/archives/C015S2QUY3H/p1593402720000200";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => ( <Redirect goto_url={url} /> );
export default IndexPage;
